<div class="container">


    <h2 class="section-title wow fadeInUp">Team</h2>

    <div class="spacer-60"></div>

    <div class="row blog-wrapper justify-content-center">




        <div class="col-md-4">
            <!-- blog item -->
            <div class="blog-item rounded2 bg-white shadow-dark wow fadeIn">
                <div class="thumb">

                    <a href="javascript:" (click)="scrollTo('home')">
                        <img src="assets/images/eriz.jpeg" alt="blog-title" />
                    </a>
                </div>
                <div class="details">
                    <h4 class="my-0 title"><a href="javascript:">Eriz Zárate</a></h4>
                    <ul class="list-inline meta mb-0 mt-2">
                        <li class="list-inline-item">Founder & Managing Director</li>

                    </ul>
                </div>
            </div>
        </div>

    </div>

</div>