<div class="container">

    <!-- section title -->
    <h2 class="section-title wow fadeInUp">What we do</h2>

    <div class="spacer-60"></div>

    <div class="row">

        <div class="col-md-4">
            <!-- service box -->
            <div class="service-box rounded data-background padding-30 text-center text-light shadow-blue bg-blue">
                <img src="assets/images/landing/building.png" style="margin-bottom: 0; width:120px ;" alt="UI/UX design" />
                <h3 class="mb-3 mt-0">Specialise in building</h3>
                <p class="mb-0">Both mobile and web apps because we’re passionate and die for creating new cool stuff that has interesting technical and business model possibilities and that makes strong use of technology and positions itself in a business niche. </p>
            </div>
            <div class="spacer-30 d-md-none d-lg-none"></div>
        </div>

        <div class="col-md-4">
            <!-- service box -->
            <div class="service-box rounded data-background padding-30 text-center shadow-yellow bg-yellow">
                <img src="assets/images/service-2.svg" style="margin-top: 20px;" alt="UI/UX design" />
                <h3 class="mb-3 mt-0">Competitive environment</h3>
                <p class="mb-0">
                    We focus on the details that get people spreading the word. We do this by combining users motivation and utilising innovative technology to produce standout experiences that people can’t live without.</p>
            </div>
            <div class="spacer-30 d-md-none d-lg-none"></div>
        </div>

        <div class="col-md-4">
            <!-- service box -->
            <div class="service-box rounded data-background padding-30 text-center text-light shadow-pink bg-pink">
                <img src="assets/images/service-3.svg" style="margin-top: 20px;" alt="UI/UX design" />
                <h3 class="mb-3 mt-0">Specialized in technologies</h3>
                <p class="mb-0">
                    We are specialized in technologies such as applied AI, quantum computing, quantitative analysis, logistics and decentralized finance.</p>
            </div>
        </div>

    </div>

    <div class="mt-5 text-center">
        <p class="mb-0">If you have an idea of whatever complexity, we got you covered. </p>
    </div>

</div>