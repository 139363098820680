<div class="container">

    <!-- section title -->
    <h2 class="section-title wow fadeInUp">The Resilience Tech</h2>

    <div class="spacer-32"></div>

    <div class="row">



        <div class="col-md-12  details">
            <p> <strong>Founders are resilient by nature, but sometimes their projects aren't.</strong> <br> <br> Let's be honest: <br><br> Each and every business in the world has to be resilient. <br> <br> Ever changing world dynamics and new disrupting
                technologies make a strong case for building technology that has this attribute as its core pilar. <br><br> What really matters is the core detail-oriented and modern approach; we are obsessed for details and for creating completely experiential
                experiences, which are the ones that are most likely to stand-out. <br><br> As a second core pilar, we surpass the expectations of just generating one suitable business model for each project as we consider crucial to expand the reach
                and have the ability to iterate.
            </p>
        </div>

    </div>
    <!-- row end -->


</div>