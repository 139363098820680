<div class="container">

    <!-- section title -->
    <h2 class="section-title wow fadeInUp">How we do it</h2>


    <div class="spacer-30"></div>
    <div class="section-description wow fadeInUp">We are a one-stop boutique that any founder, inventor or disruptor can rely on. We know which are the attributes and needs of succesfull projects, so you can trust we will handle your project from zero to hero. </div>
    <div class="spacer-30"></div>
    <div class="section-description wow fadeInUp">In a nutshell, we: </div>
    <div class="spacer-30"></div>

    <div class="row">

        <div class="col-md-12">

            <!-- timeline wrapper -->
            <div class="timeline edu rounded bg-white shadow-dark padding-30 overflow-hidden">

                <!-- timeline item -->
                <div class="timeline-container wow fadeInUp">
                    <div class="content">
                        <span class="time">Initial research</span>

                        <p>Do a complete initial research of the business niche, potential competitors and business model applicability so that we are sure we are the in the right path to succeed.</p>
                    </div>
                </div>

                <!-- timeline item -->
                <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
                    <div class="content">
                        <span class="time">Design</span>

                        <p>Desing whole interfaces with modern UX/UI architectures and patterns that will be completely experimental for the users using them.</p>
                    </div>
                </div>

                <!-- timeline item -->
                <div class="timeline-container wow fadeInUp" data-wow-delay="0.4s">
                    <div class="content">
                        <span class="time">Develop</span>

                        <p>Implement and develop those interfaces into the prefferred format (webApp, native apps) using cutting edge technologies.</p>
                    </div>
                </div>

                <div class="timeline-container wow fadeInUp" data-wow-delay="0.6s">
                    <div class="content">
                        <span class="time">Market</span>

                        <p>Market the project putting all the focus into revenue increase via digital marketing and growth hacking techniques and methodologies.</p>
                    </div>
                </div>

                <div class="timeline-container wow fadeInUp" data-wow-delay="0.8s">
                    <div class="content">
                        <span class="time">Support</span>

                        <p>Support on future steps of the scalability plan apart from being very team to the founder/founders team to really feel what they have in their minds.</p>
                    </div>
                </div>

                <!-- main line -->
                <span class="line"></span>

            </div>

        </div>



    </div>

</div>